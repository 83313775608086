p {
  line-height: 1.8;
}

a {
  text-decoration: none;
}


h2 {
  margin-top: 0em;
  font-size: 1.5em;
}

.TokenizeMeLogo{
  opacity: 0.7;
  height: 1.1em;
  width: 1.2em;
}

.SummarizeMeLogo{
  opacity: 0.7;
  height: 1em;
  width: 1em;
}