body {
  margin: 0;
  font-family: 'Cabin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-display: swap;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  background: var(--bkg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-display: swap;

}

.dark {
  --text-color: #E5E5E5;
  --icon-color:#e5e5e590;
  --bkg-color: #03080f;
  --heading-color: #E5E5E5;
  --hr-color:#FFB900;
}

.light {
  --text-color: #1D2C4D;
  --bkg-color: #edf1fb;
  --icon-color:#1d2c4dc7;
  --heading-color: #1D2C4D;
  --hr-color:#FFB900;
}

