.Landing h2 {
  padding:0px 0px 0px 0px;
  text-align: center;
  font-size: 2em;
  font-weight: normal !important;
}


h1 {
  font-size: 3em;
}