.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Cabin', 'Raleway', sans-serif;
  font-display: swap;
  width: 90vw;
}

hr {
  color:  var(--hr-color);
  /*opacity: 10%;*/
  fill: var(--hr-color);
  border: 0;
  border-top: 1px solid;
}

a {
  color:  var(--text-color);
}

ul {

  padding: 0px;
  margin: 0px;

}

li > a > svg {
  color:  var(--icon-color);
  opacity: 1000%;
}

li > a > svg > path {
  color:  var(--icon-color);
  opacity: 1000%;
}

.react-toggle-dark-mode-animation {
  align-self: flex-end;
  width: 30px;
  height: 30px;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  }
  
  
  h2 {

    padding:0px;
    padding-top: 0.4em;
    font-size: 1.5em;
    font-weight: normal !important;
    color:  var(--heading-color);
  }
  
  li {
    display: inline-block;
    margin-right: 0.4em;
    margin-left: 0.4em;
    margin-top: 0.5em;
    font-size: 1.8em;
  }



